@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);
@import url(https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arial:wght@300;400&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Arial:wght@300;400;600;700&display=swap"); */

body {
  font-family: "Arial" !important;
  text-align: center !important;
  width: 100%;
  height: 100%;
  background-color: #ffffff !important;
}

.center_keeping_conatiner {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
}

button.disbalesubmitbtn {
  text-transform: none !important;
  border-radius: 2px;
  background: #ffffff !important;
  width: 420px !important;
  height: 42px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  opacity: 0.5;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

button.cancelbtn {
  text-transform: none !important;
  border-radius: 2px;
  background: #ffffff !important;
  width: 420px !important;
  height: 42px;
  margin-top: 20px;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  margin-bottom: 50px;
  background: #ffffff;
  border: 1px solid #c09507;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

button.editbtn {
  text-transform: none !important;
  border-radius: 2px;
  background: #ffffff !important;
  width: 420px !important;
  height: 42px;
  margin-top: 30px;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  margin-top: 50px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #585d60;
}

button.submitbtn {
  width: 420px !important;
  text-transform: none !important;
  color: #ffffff;
  height: 42px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  background: #c09507;
  border-radius: 2px;
  border: 2px solid #c09507 !important;
  box-shadow: none !important;
}

button.submitbtn:hover {
  background-color: #ab8405 !important;
}

.width100percent {
  width: 100%;
}

.mainheading {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 138.69%;
  color: #3d4a52;
}

.editheading {
  font-weight: 700;
  font-size: 20px;
  color: #3d4a52;
}

.secondmainheading {
  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 138.69%;
  color: #3d4a52;
}

.fontweight600andfontsize14pxandcolor3A3A3A {
  font-weight: 600;
  font-size: 14px;
  color: #3a3a3a;
}

.fontweight600andfontsize14pxandcolorFFFFFF {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.fontweight600andfontsize14pxandcolor3D4A52 {
  font-weight: 600;
  font-size: 14px;
  color: #3d4a52;
}

.fontweight400andfontsize14pxandcolor3D4A52 {
  font-weight: 400;
  font-size: 14px;
  color: #3d4a52;
}

.fontweight400andfontsize16pxandcolor3D4A52 {
  font-weight: 400;
  font-size: 16px;
  color: #3d4a52;
}

.thirdmainheading {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138.69%;
  color: #3d4a52;
}

.mainheadingsuccess {
  font-weight: 700;
  font-size: 20px;
  color: #3d4a52;
  font-family: "Arial";
  font-style: normal;
}

.secondmainheadingsuccess {
  font-weight: 700;
  font-size: 20px;
  color: #d8af28;
  font-family: "Arial";
  font-style: normal;
}

.thirdmainheadingsuccess {
  font-weight: 400;
  font-size: 16px;
  color: #3d4a52;
  font-family: "Arial";
  font-style: normal;
}

.css-1gsv261 {
  border-bottom: 1px solid #c09507 !important;
}

.boldimage {
  display: none;
}

.nonboldimage {
  display: block;
}

.react-multi-email > input {
  background: none !important;
}

.react-multi-email {
  width: 100% !important;
  border: none !important;
  background: none !important;
  border-bottom: 1px solid #9aa1a9 !important;
  border-radius: inherit !important;
}

.invite-participant-text-editor > div:first-child {
  background: #f1f1f1 !important;
  border: none !important;
  border-bottom: 1px solid #9aa1a9 !important;
  border-radius: inherit !important;
}

.invite-participant-text-editor > div:first-child > div:first-child {
  text-align: left;
}

.settingsTabs .Mui-selected {
  color: #ffffff !important;
  border-radius: 2px 0px 0px 0px;
  text-transform: none !important;
  background: #c09507;
  width: 220px;
  height: auto;
  font-weight: 400;
  border-bottom: none !important;
  border-left: 1px solid #d8af28 !important;
  border-right: 1px solid #d8af28 !important;
  border-top: 1px solid #d8af28 !important;
}

.particaipancard .MuiCardHeader-title {
  padding-bottom: 18px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #3d4a52;
  line-height: 19px;
}

.particaipancard .MuiCardHeader-subheader {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  opacity: 0.5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.downloadDataset {
  color: #3491ee !important;
}

.downloadDataset:hover {
  text-decoration: underline !important;
}

.MuiDataGrid-columnHeaderTitle {
  text-transform: capitalize !important;
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #a3b0b8 !important;
  font-family: "Arial" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.css-19mk8g1-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: 2px solid #a3b0b8 !important;
}

.MuiFilledInput-input,
.MuiInputLabel-root {
  color: #3d4a52 !important;
  font-family: "Arial" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
}

.css-1wc848c-MuiFormHelperText-root {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.css-1wc848c-MuiFormHelperText-root.Mui-error {
  color: #ff3d00 !important;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

button.disbalesubmitbtnteam {
  text-transform: none !important;
  border-radius: 2px;
  box-shadow: none !important;
  background: #ffffff !important;
  width: 420px !important;
  height: 42px;
  margin-top: 200px;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  opacity: 0.5;
  font-weight: 700;
}

button.submitbtnteam {
  width: 420px !important;
  text-transform: none !important;
  color: #ffffff;
  height: 42px;
  margin-top: 200px;
  background: #c09507;
  border-radius: 2px;
  border: 2px solid #c09507 !important;
  box-shadow: none !important;
  font-weight: 700;
}

button.submitbtnteam:hover {
  background-color: #ab8405 !important;
}

button.submitbtn:hover {
  background-color: #ab8405 !important;
}

button.cancelbtnteam {
  text-transform: none !important;
  border-radius: 2px;
  background: #ffffff !important;
  width: 420px !important;
  height: 42px;
  margin-top: 30px;
  font-weight: 700;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  margin-bottom: 50px;
  box-shadow: none !important;
}

.backlabel {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138.69%;
  color: #3491ee;
}

.link :hover {
  cursor: pointer;
}

.react-multi-email [data-tag] {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #3d4a52 !important;
}

.public-DraftEditorPlaceholder-root {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #3d4a52 !important;
}

.tabstyle {
  width: 109px;
  height: 19px;
  font-family: "Arial" !important;
  font-size: 14px;
  font-weight: 400;
}

.buttonremovebackgroundonhover:hover {
  background: none !important;
}

.widht640andheight368pxandborderradius10andborder1pxsolidE4E4E4 {
  width: 640px;
  height: 368px;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
}

.separatorline {
  margin-left: -200px;
  margin-right: -200px;
  margin-top: 30px;
  border-top: 1px solid rgba(238, 238, 238, 0.5);
}

.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  color: #3d4a52 !important;
}

.MuiLinearProgress-bar1Indeterminate {
  background-color: #00a94f !important;
}

.MuiLinearProgress-bar2Indeterminate {
  background-color: #00a94f !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: white !important;
}

MuiLinearProgress-determinate {
  background-color: #00a94f !important;
}

.MuiLinearProgress-colorSuccess MuiLinearProgress-determinate {
  background-color: white !important;
}

/* hiding drag and drop shadow issue */
.sc-gsnTZi {
  display: none;
}

/* checkbox */
.Mui-checked {
  color: #c09507 !important;
}

/* switch */
.MuiSwitch-track {
  background-color: #c09507 !important;
}

/* mui typography  */
.MuiTypography-root {
  font-family: "Arial" !important;
}

.tocapitalizethefirstletter {
  text-transform: capitalize;
}

.memberDataSetCardTooltipAndWidthAndOverflowWidth200 {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px !important;
}

.memberDataSetCardTooltipAndWidthAndOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_overflow_ellipsis_overflow_hidden {
  text-overflow: ellipsis;
  overflow: hidden;
}

.width100px {
  width: 100px;
}

.width150px {
  width: 150px;
}

.width173px {
  width: 173px;
}

.width200px {
  width: 200px;
}

.width250px {
  width: 250px;
}

.width260px {
  width: 250px;
}

.width270px {
  width: 270px;
}

.width300px {
  width: 300px;
}

.width300px_mt5px {
  width: 300px;
  margin-top: 5px;
}

.width600px {
  width: 600px;
}

.width1000px {
  width: 1000px;
}

.classForPtag {
  margin: 0px auto;
  word-wrap: break-word;
}

.carousel-indicators {
  position: absolute;
  right: 0px;
  bottom: 0;
  left: 173px !important;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
  width: 72px !important;
}

.carousel-control-next,
.carousel-control-prev {
  z-index: 200 !important;
}

.carousel-control-next {
  right: 241px !important;
  height: 200px !important;
  top: 271px !important;
  height: 30px !important;
  width: 30px !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAMCAYAAACwXJejAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACUSURBVHgBfZDdDYMwDIQvYZaqafvCKqgDtJ2EMElZAEVMAi+Iv10MJgjET8K9JCd/PtkGWG2u4taoGB6JytzCAEFhDaDvUZMcIfmK+nIAfpPhV7sSxfKpjfqy+bsSxbbDB4pjtAuUpyHlNNosojlkl8Sn+AyE1C5BSB7vRu8gH7BCV4CFOvMMCVT4gO1Gus6Uu8gaASmWSvwrlvO5AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: center !important;
}

.carousel-control-prev {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAMCAYAAACwXJejAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB7SURBVHgBfdDJDYAgEAXQcZoR48lKjB1oJVCKFmCInXgSKEbQ0QQlLP80hBeWD5CJ2Rg3knGaqxxwFgTNJ2CHJeAApnY49qoEmkHPwXU54FEJUJ43WfudiEgujN9Ukol78X4ZYax7vUSoBKOeUjBZ5h9SmZALQbUyQfMFvqREsxnYMV4AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  left: 240px !important;
  height: 200px !important;
  top: 271px !important;
  height: 30px !important;
  width: 30px !important;
  background-position: center !important;
}

.carousel-control-prev-icon {
  display: none !important;
}

.carousel-control-next-icon {
  display: none !important;
}

.cardheaderTitlespecifier .css-et1ao3-MuiTypography-root {
  width: 250px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.cardheaderTitlespecifier .css-et1ao3-MuiTypography-root {
  width: 250px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.cardheaderTitlespecifier .css-83ijpv-MuiTypography-root {
  width: 250px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.red {
  border: 1px solid red;
}

.successIconInsideCardheader {
  width: 22px;
  height: 21px;
}

.MuiCardHeader-content {
  position: relative;
}

.MuiCardHeader-content .successIconInsideCardheader {
  position: absolute;
  right: 11px;
  bottom: 0px;
}

.dockerImageURL {
  color: #3491ee;
  cursor: pointer;
}

.minHeightWithoutFooter {
  min-height: 500px;
}

.minHeight232px {
  min-height: 232px;
}

.minHeight501pxsettingpagemaindiv {
  min-height: 501px;
}

.minHeight67vhParticipantPage {
  min-height: 67.5vh;
  /* margin-top: 100px; */
}

.minHeight67vhDatahubPage {
  /* margin-top: 100px; */
  min-height: 67.5vh;
}

.hover {
  cursor: pointer;
}

.Departmentcard .MuiCardHeader-title {
  padding-bottom: 1px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #3d4a52;
  line-height: 19px;
  margin-left: 8px;
}

.Departmentcard .MuiCardHeader-subheader {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 8px;
  /* identical to box height */

  color: #000000;

  opacity: 0.5;
}

button.submitbtndept {
  width: 420px !important;
  text-transform: none !important;
  color: #ffffff;
  margin-top: 80px;
  height: 42px;
  background: #c09507;
  border-radius: 2px;
  border: 2px solid #c09507 !important;
  box-shadow: none !important;
  font-weight: 700;
}

button.submitbtndept:hover {
  background-color: #ab8405 !important;
}

button.cancelbtndept {
  text-transform: none !important;
  border-radius: 2px;
  background: #ffffff !important;
  width: 420px !important;
  height: 42px;
  margin-top: 30px;
  font-weight: 700;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  margin-bottom: 10px;
  box-shadow: none !important;
}

button.disbalesubmitbtndept {
  text-transform: none !important;
  border-radius: 2px;
  box-shadow: none !important;
  background: #ffffff !important;
  margin-top: 80px;
  width: 420px !important;
  height: 42px;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  opacity: 0.5;
  font-weight: 700;
}

.recharts-tooltip-wrapper {
  border: none !important;
}

.recharts-default-tooltip {
  border: none !important;
}

.text-capital {
  text-transform: capitalize !important;
}

.supportcardsecondcolumn .supportEnableButton {
  text-transform: none !important;
}

.searchBarForDataset {
  display: flex;
  width: 100%;
  padding: 10px 10px !important;
  justify-content: right;
  transition: width 2s;
}

#categories-main-container .Mui-selected {
  color: black !important;
  background-color: white !important;
  border: none !important;
}

#categories-main-container .MuiTreeItem-label {
  width: auto !important;
}

.viewdatasetsecondheading {
  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 1px;
  line-height: 138.69%;

  /* headings */

  color: #3d4a52;
}

.separatorlineview {
  margin-left: -200px;
  margin-right: -200px;
  margin-top: 30px;
  border-top: 1px solid rgba(237, 22, 22, 0.5);
}

.downloadfiles {
  margin-top: 30px;
}

.downloadfiles :hover {
  color: #3491ee !important;
}

.solid {
  border-left: 1px solid #bbb;
  width: 300px;
  margin-left: -100px;
  margin-top: 50px;
}

.horizontal {
  border-left: 1px solid #bbb;
  height: 300px;
}

.thirdmainheadingview {
  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 138.69%;
  /* or 19px */

  /* headings */

  color: white;
  align-items: center;
  text-align: center;
}

.ant-message-notice-content {
  position: absolute !important;
  left: 0px !important;
}

.dataset_selector_in_integration .MuiSvgIcon-root {
  color: #000000 !important;
}

.dataset_selector_in_integration .row {
  margin-top: 0px !important;
}

.dataset_selector_in_integration .ant-segmented-item-selected {
  margin-bottom: 0px !important;
}

.dataset_selector_in_integration .MuiCollapse-entered {
  background: #ffffff !important;
  box-shadow: 0px 20px 40px -4px rgb(145 158 171 / 16%) !important;
  border-radius: 8px !important;
}

.react-multi-email input {
  min-width: 500px !important;
}

.carousel_in_guest_user_main_page .slick-arrow {
  background-color: #00a94f !important;
  border-radius: 20px !important;
}

.carousel_in_guest_user_main_page {
  display: contents;
}

.back_btn_guest .MuiSvgIcon-root {
  color: #3491ee !important;
}

.dataset_selector_in_integration .MuiFormControlLabel-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dataset_selector_in_integration .deleteicon {
  cursor: pointer;
}

.dataset_selector_in_integration .deleteicon:hover {
  color: red !important;
}

.deleteicon:hover .mainBoxInIntegration {
  border: 1px solid red !important;
}

.dataset_integration_selectors .row {
  background-color: #ab8405 !important;
}

html {
  scroll-behavior: smooth;
}

.MuiInputLabel-asterisk {
  color: red !important;
}

.all_selectors_as_sticky {
  transition: all 0.2s;
}

.ant-affix .all_selectors_as_sticky {
  box-shadow: 0px 2px 5px rgb(0 0 0 / 50%) !important;
  margin: 0px !important;
  padding: 5px 0px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.connectorCard {
  padding: 20px 40px 20px 5px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #3d4a52;
  line-height: 19px;
  border-radius: 10px;
  width: 368px;
  height: 178px;
  margin: 30px 15px 15px 15px;
  border: 1px solid #ffdb64;
  outline: none;
}

.connectorCard:hover {
  box-shadow: 0px 4px 20px rgba(216, 175, 40, 0.28);
  border: 1px solid #ffdb64;
  cursor: pointer;
}

.dataset_selector_in_integration .backButtonMainDiv .MuiSvgIcon-root {
  color: #3491ee !important;
}

.ant-segmented-group label {
  margin-bottom: 0px !important;
}

.button:active {
  background-color: #00a94f !important;
}

.MuiStepLabel-label {
  font-family: "Arial" !important;
}

input,
textarea {
  font-family: "Arial" !important;
  font-size: 16px !important;
}

.stepper_ .Mui-completed,
.stepper_ .Mui-active {
  font-weight: 600 !important;
}

.mainOnboardingParent .MuiStepper-root {
  padding: 0px 150px !important;
}

#country_select {
  font-family: "Arial" !important;
  font-size: 16px !important;
  text-align: left !important;
}

/* animation for the onboarding/ */
.step-enter {
  opacity: 0;
  transform: translateX(100%);
}

.step-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 500ms, transform 500ms;
}

.step-exit {
  opacity: 1;
  transform: translateX(0%);
}

.step-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms, transform 500ms;
}

#panel1bh-header .MuiInputBase-root:nth-child(1) {
  height: 28px !important;
}

.textarea:active {
  border: none !important;
  outline: none !important;
}

.input_field_subscribe {
  border-radius: 8px !important;
  width: 466px !important;
  height: 56px;
}

.input_field_subscribe
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  padding-right: 0px !important;
  border-radius: 8px !important;
}

.input_field_subscribe_sm {
  border-radius: 8px !important;
  width: 327px !important;
  height: 30px !important;
  padding: 0px;
}

.input_field_subscribe_sm
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  padding-right: 0px !important;
  border-radius: 8px !important;
  height: 30px !important;
  width: 327px !important;
  padding: 0px;
}

.input_field_subscribe_md {
  border-radius: 8px !important;
  width: 273px !important;
  height: 30px !important;
  padding: 0px;
}

.input_field_subscribe_md
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  padding-right: 0px !important;
  border-radius: 8px !important;
  height: 32px !important;
  width: 273px !important;
  padding: 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.ml-11 {
  margin-left: 11px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-44 {
  margin-right: 44px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mr4 {
  margin-right: 4px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mt-39 {
  margin-top: 39px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.fw600 {
  font-weight: 600 !important;
}

.breadcrumbItem:hover {
  color: #00a94f !important;
}

.MuiSnackbar-root {
  top: 95px !important;
}

.category_detail_main_box .MuiInputAdornment-root {
  height: 25px !important;
}

.category_detail_main_box .MuiInputAdornment-root .btn-primary {
  padding: 0px 10px !important;
}

.rich_text_editor select,
.rich_text_editor button {
  border: none !important;
  font-family: "Arial" !important;
  text-align: center;
}

.rich_text_editor {
  text-align: left;
}

.rich_text_editor .public-DraftEditorPlaceholder-inner::after {
  content: " *";
  color: red !important;
}

.rich_text_editor .DraftEditor-root,
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  height: 150px !important;
  max-height: 250px !important;
  font-family: "Arial" !important;
}

.edit_head_name_accordion {
  width: 950px;
  height: 50px;
  margin: 20px auto !important;
}

.accordions_in_onboarding .MuiAccordionSummary-content {
  align-items: center !important;
  justify-content: space-between;
}

.stepper_ .DraftEditor-editorContainer,
.DraftEditor-root,
.DraftEditor-editorContainer {
  overflow: auto !important;
  display: contents !important;
}

.requestTableContainer .MuiTableCell-root {
  border-left: none !important;
}

.MuiSlider-colorPrimary {
  color: #00a94f !important;
}

.fabIcon {
  background-color: white !important;
}

.ant-drawer-content-wrapper {
  top: 88px !important;
}

.ant_drawer_in_req_api .ant-drawer-title {
  text-align: left;
  font-weight: 700;
  font-size: 24px;
}

.ant_drawer_in_req_api .ant-drawer-body {
  padding: 9px 35px 0px 48px !important;
}

.generate_key_main_box .MuiSvgIcon-root {
  color: white !important;
}

/* .data_table_inside_dataset_details_view .ant-table-header th:first-child {
  background-color: #000000 !important;
} */
/* .data_table_inside_dataset_details_view .ant-table-tbody tr:hover {
  background-color: #beffde !important;
} */
.data_table_inside_dataset_details_view .row-hover,
.data_table_inside_dataset_details_view .ant-table-cell {
  font-family: "Arial" !important;
}

.data_table_inside_dataset_details_view .ant-table-cell {
  text-transform: capitalize !important;
}

/* Add this CSS to your stylesheets or CSS-in-JS solution */
.data_table_inside_dataset_details_view .row-hover {
  transition: background-color 0.3s ease;
}

.data_table_inside_dataset_details_view .ant-table-title,
.data_table_inside_dataset_details_view .ant-table-title button {
  font-family: "Arial" !important;
}

.data_table_inside_dataset_details_view .row-hover:hover {
  background-color: #beffde !important;
  /* Change this color as needed */
}

.ant-pagination-item-active {
  background-color: #00a94f !important;
  color: white !important;
  border: 1px solid #00a94f !important;
  margin-right: 5px !important;
  font-family: "Arial" !important;
}

.ant-pagination-item {
  font-family: "Arial" !important;
}

.ant-pagination-options {
  display: none !important;
  visibility: hidden !important;
  font-family: "Arial" !important;
}

.select_all_in_api_consume .MuiSvgIcon-root {
  color: white !important;
}

.category_card_on_landing_page_dataset:hover {
  border: 0.5px solid;
  color: #00a94f !important;
}

.first_category_card_on_landing_page_dataset {
  border: 0.5px solid !important;
}

.main_box_for_dataset_listing .ant-card-head-title {
  margin: 20px 0px !important;
}

.main_box_for_dataset_listing .ant-card-head {
  padding: 5px !important;
}

.mt-180 {
  margin-top: 150px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.scrolled {
  height: 0px !important;
  transition: all 2s;
}

.each_list_in_masking_dataset span {
  font-size: 24px !important;
}

.each_list_in_masking_dataset:hover {
  background-color: #eafbf3;
}

.mainBoxForGuestHome .row {
  margin: 0px !important;
}

#click-support-icon {
  display: none !important;
}

.pariticipants_list_and_new_request .MuiTabs-scrollButtons {
  display: none !important;
}

.main_container_for_dashboard_and_api .row {
  margin: 0px !important;
}

.feedback_system .thumbs_down {
  color: red !important;
}
.thumbs_down:hover,
.thumbs_up:hover {
  scale: 1.1;
}

.GuestUserContactNew_title__3ZdPV {
  font-family: "Arial", sans-serif;
  font-weight: 300;
  font-size: 64px;
  line-height: 78px;
  text-align: center;
  color: #585d60;
}

.GuestUserContactNew_description__8emQE {
  font-family: "Arial", sans-serif;
  font-weight: 400 !important;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  font-style: italic !important;
  color: #565c63 !important;
  margin-top: 32px;
}

.GuestUserContactNew_descriptionSm__22e_J {
  font-family: "Arial", sans-serif;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 31px;
  text-align: center;
  font-style: italic !important;
  color: #565c63 !important;
  margin-top: 32px;
}

.GuestUserContactNew_titleContainer__1MMEq {
  justify-content: center;
}

.GuestUserContactNew_title2__3qeO5 {
  margin: 50px 0;
}

.GuestUserContactNew_backButton__1ud2I {
  /* margin-top: 50px !important;
      margin-bottom: 50px !important; */
  margin: 0 15px !important;
  font-size: 15px !important;
}

.GuestUserContactNew_backButtonContainer__2x9HV {
  display: flex;
  flex-direction: row-reverse;
  padding: 50px 0 0 0;
}

.GuestUserContactNew_backButtonContainerAlingCenter__2HDt6 {
  padding: 50px 0;
}

.GuestUserContactNew_backButtonContainerAlingCenter__2HDt6 > button {
  margin: auto !important;
  width: 350px;
}

.GuestUserContactNew_borderNone__f3tNc {
  border: none !important;
  margin: 0 15px !important;
}

.GuestUserContactNew_primary_button__31Zwd {
  margin: 0 15px !important;
  padding: 10px 35px !important;
  font-size: 15px !important;
}

.GuestUserContactNew_radioButtonContainer__25ew0 {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.GuestUserContactNew_radioButtonContainerSm__3xYHA {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.GuestUserContactNew_adminDetailsContainer__20Cr1 {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.GuestUserContactNew_adminDetailsCol__20JJ4 {
  margin: 15px 0;
}

/* Add all the new ui css classes here and to use it just import the global module file and use it as variable */

.global_primary_color__1byx1 {
  color: #00a94f !important;
}

.global_primary_fontStyle__217Lj {
  font-family: Arial !important;
}

.global_dark_color__3gJO7 {
  color: #000000 !important;
}

.global_primary_background__1wXaa {
  background-color: #00a94f !important;
}

.global_primary_buttonSupport__3UHvy {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 26px !important;
  height: 48px !important;
  background: #00a94f !important;
  border-radius: 8px !important;
  align-items: center !important;
  padding: 11px 38px !important;
  color: #ffffff !important;
  text-transform: none !important;
}
.global_primary_buttonSupport__3UHvy:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.global_outlined_button__uChAm {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 26px !important;
  height: 48px !important;
  border-radius: 8px !important;
  padding: 11px 38px !important;
  color: #00a94f !important;
  text-transform: none !important;
  border: 1px solid #00a94f !important;
  background-color: #ffffff !important;
}

.global_outlined_button__uChAm:active {
  color: #00a94f !important;
  border: 1px solid #00a94f !important;
  background-color: #ffffff !important;
}

.global_white_background__1Sebx {
  background: #ffffff !important;
}

.global_light_text__2tI-T {
  color: #919eab !important;
}

.global_highlighted_text__2SmZC {
  color: #212b36 !important;
}
.global_highlighted_text_in_home__3BoAf {
  color: white !important;
}

.global_title_text__14VRP {
  color: #00a94f !important;
}

.global_bold300__2I8TE {
  font-weight: 300 !important;
}

.global_bold400__2m0CT {
  font-weight: 400 !important;
}

.global_bold500__2Tyf_ {
  font-weight: 500 !important;
}

.global_bold600__3zp6D {
  font-weight: 600 !important;
}

.global_bold700__udlwq {
  font-weight: 700 !important;
}

.global_size12__3QUSJ {
  font-size: 12px !important;
}

.global_size14__1UCEi {
  font-size: 14px !important;
}

.global_size16__3MUoC {
  font-size: 16px !important;
}

.global_size18__29UXX {
  font-size: 18px !important;
}

.global_size20__n-Zm_ {
  font-size: 20px !important;
}

.global_size22__yQrhv {
  font-size: 22px !important;
}

.global_size24__34zrI {
  font-size: 24px !important;
}

.global_size26__31RSE {
  font-size: 26px !important;
}

.global_size28__iERpP {
  font-size: 28px !important;
}

.global_size30__chnDO {
  font-size: 30px !important;
}

.global_size32__2N2xe {
  font-size: 32px !important;
}

.global_size34__1Wpl- {
  font-size: 34px !important;
}

.global_size36__d_ZyJ {
  font-size: 36px !important;
}

.global_size38__5ob4D {
  font-size: 38px !important;
}

.global_size40__3jxbJ {
  font-size: 40px !important;
}

.global_size45__259Ng {
  font-size: 45px !important;
}

.global_size64__3_Vfc {
  font-size: 64px !important;
}

.global_blue__1wldx {
  color: #3366ff;
}

.global_padding0__2f2E- {
  padding: 0 !important;
}

.global_font_family__3gP14 {
  font-family: Arial !important;
}

/* all buttons */
.global_primary_button__33Zcr {
  background: none !important;
  background-color: #00a94f !important;
  font-family: Arial !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 26px;
  outline: none !important;
  border: none !important;
  text-transform: none !important;
  cursor: pointer !important;
  margin: 0px !important;
  color: #ffffff !important;
  border-radius: 8px !important;
}

.global_primary_button__33Zcr:active {
  background-color: #00a94f !important;
  outline: none !important;
  border: none !important;
}

.global_primary_button__33Zcr:disabled {
  background: rgba(145, 158, 171, 0.24) !important;
  border-radius: 8px !important;
  color: #ffffff !important;
}

.global_secondary_button__1pnu9 {
  font-family: Arial !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  letter-spacing: 0px;
  font-weight: 700 !important;
  background-color: unset !important;
  color: #212b36 !important;
  text-transform: none !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin: 0px !important;
}

.global_secondary_button__1pnu9:active {
}

.global_secondary_button__1pnu9:hover {
}

.global_secondary_button_error__1mk_A {
  font-family: Arial !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  letter-spacing: 0px;
  font-weight: 700 !important;
  background-color: unset !important;
  color: #ff5630 !important;
  text-transform: none !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin: 0px !important;
}

.global_tertiary_button__h1Sl- {
  /* background: none !important;
  background-color: #00A94F !important;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  outline: none !important;
  border: none !important;
  text-transform: none !important;
  cursor: pointer !important; */
}

.global_error__zprBI {
  color: red !important;
}
.global_textDescription__3bHli {
  line-height: 27px !important;
  margin: auto !important;
  font-size: 16px !important;
  font-style: italic !important;
  color: #565c63 !important;
  text-align: left;
}
.global_break_word__19NFs {
  word-break: break-all;
}
.global_ellipses__2HmnR {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.global_homeButtonWidth__2uKhW {
  width: 368px !important;
}


#admin_add_dataset_main_container .Mui-selected {
  background-color: #c09507 !important;
  color: white !important;
  text-transform: none;
}

.dataset_name_class {
  width: 80%;
}

.main_heading_row {
  text-align: left;
}

.Main_heading_add_dataset {
  font-weight: 600;
  font-size: 24px;
}

.connect_btn {
  background-color: white !important;
  color: black !important;
  border: 1px solid #c09507 !important;
  width: 80%;
  text-transform: capitalize;
  margin: 10px 0px;
}

.connect_btn:hover {
  background-color: #c09507 !important;
  color: white !important;
  border: 1px solid #c09507;
}

.textfield_row {
  text-align: left !important;
}

.database_img {
  transition: all 2s;
}

.paperForTotal {
  background-color: #c09507 !important;
  color: white !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  /* padding: 10px; */
  display: block !important;
  /* height: 80px !important; */
  border: 1px solid;
}

.statusInsidePaper {
  /* background-color: #c09507 !important; */
  color: white !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* border-radius: 4px; */
  /* box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%); */
  /* padding: 10px; */
  /* display: block !important; */
  /* height: 40px !important; */
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.disconnect_btn {
  background-color: white !important;
  color: red !important;
  width: 80%;
  text-transform: capitalize;
  margin: 10px 0px;
  border: 1px solid red !important;
  outline: none !important;
}

.disconnect_btn:hover {
  color: white !important;
  background-color: red !important;
}

.green_btn_for_connect {
  border: 1px solid #00a94f !important;
  color: #00a94f !important;
  margin: 0px auto !important;
  display: block !important;
}

.green_btn_for_connect:hover {
  border: 1px solid #00a94f !important;
  color: white !important;
  background-color: #00a94f !important;
}

.MuiSvgIcon-root {
  /* background-color: #c09507 !important; */
  color: #00a94f !important;
}

.MuiStepConnector-horizontal.Mui-completed,
.MuiStepConnector-horizontal.Mui-active,
.MuiStepConnector-horizontal.Mui-active span,
.MuiStepConnector-horizontal.Mui-completed span {
  border: 1px solid #00a94f !important;
  background-color: #00a94f !important;
}

/* .Mui-completed {
    font-weight: 600 !important;
    font-size: 20px !important;
} */
.button_main_box button {
  width: 20%;
  text-transform: capitalize !important;
}

.button_main_box .next_btn {
  color: #c09507 !important;
  border: 1px solid #c09507 !important;
}

.button_main_box .next_btn:hover {
  color: white !important;
  background-color: #c09507 !important;
}

.button_main_box .next_btn:disabled {
  color: rgba(128, 128, 128, 0.78) !important;
  background-color: rgba(128, 128, 128, 0.58) !important;
  border-color: grey !important;
}

.button_main_box .cancel_btn {
  color: red !important;
  border: 1px solid red !important;
}

.button_main_box .cancel_btn:hover {
  color: white !important;
  background-color: red !important;
}

.button_main_box .back_btn {
  color: #c09507 !important;
}

.button_main_box .back_btn:hover {
  color: #c09507 !important;
  background-color: unset !important;
}

#admin_add_dataset_main_container .submit_btn {
  width: 400px !important;
  color: #c09507 !important;
  border: 1px solid #c09507 !important;
  text-transform: capitalize !important;
  margin-top: 20px;
}

#admin_add_dataset_main_container .submit_btn:hover {
  color: white !important;
  background-color: #c09507 !important;
}

#admin_add_dataset_main_container .submit_btn:disabled {
  color: rgba(255, 255, 255, 0.45) !important;
  background-color: grey !important;
  border-color: grey !important;
  width: 400px !important;
  text-transform: capitalize !important;
}

.data_standardization_cloumn_container{
    display: flex;
    justify-content: space-around;
    /* padding-right: 200px; */
}
.data_standardization_title{
    display: flex;
    justify-content: space-around;
    /* padding-right: 300px; */
    margin-top: 30px;
    margin-bottom: 30px;
    /* position: fixed;
  top: 0; */
}
.data_standardization_title div > span{
    font-weight: 700;
    height: 50px;
    font-size: 18px;
}
.uploaded_data_column_name_title{
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    max-width: 150px;
    max-width: 300px;
    /* min-width: 100px; */
    word-wrap: break-word;
}
.uploaded_data_column_name_title_container{
    display: flex;
    align-items: center;
    /* word-wrap: break-word; */
}
.data_standardization_title:nth-child(1) span{
    display: inline-block;
    text-align: left;
}

.uploaded-data-column-names{
    display: inline-flex;
    /* border: 1px solid red !important; */
}
.data-standardization-in-add-dataset-container{
    /* max-height: 450px;
    overflow: scroll;
    position: relative; */
}

.data_standardization_column{
    max-height: 450px;
    overflow: scroll;
    position: relative;
    overflow-x: hidden;
}
.standardization-button-container{
    display: flex;
    flex-direction: row-reverse;
    padding-right: 30px;
}
.datasetform {
  overflow-x: hidden;
}

.datasetform .AddDatasetmainheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #3d4a52;
  float: left;
  /*margin-left: 20%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}

.datasetform .name {
  width: 100%;
  margin-top: 5%;
  /*margin-left: 40%;*/
}

.datasetform .description {
  float: left;
  width: 100% !important;
  margin-top: 5%;
  /*margin-left: 3%;*/
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.datasetform .checkbox1 {
  width: 100%;
  margin-left: 0px;
  margin-top: 1%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* headings */

  color: #3d4a52;
}

.datasetform .AddDatasetsecondaryheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3d4a52;
  float: left;
  /*margin-left: 20%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}

.datasetform .checkbox2 {
  width: 100%;
  /*margin-left: -40px;*/
  margin-top: 1%;
  font-family: "Open Sans";
  float: left;
}

.datasetform .checkbox2 .soil {
  /*float: left;*/
  font-family: "Open Sans";
}

.datasetform .checkbox2 .weather {
  /*float: left;*/
  font-family: "Open Sans";
  /*margin-left: -18px;*/
}

.datasetform .crop {
  width: 100% !important;
  margin-top: 10%;
  /*margin-left: 3%;*/
  float: left;
}

.datasetform .AddDatasetageheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3d4a52;
  float: left;
  /*margin-left: 41%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}

.datasetform .constantswitch {
  margin-top: 6%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3d4a52;
  margin-right: -10px;
  float: right;
}

.datasetform .radiobtns {
  /*margin-left: 20%;*/
}

.datasetform .radiobtns .sixmonth {
  margin-left: 13%;
}

.datasetform .radiobtns .ninemonth {
  margin-left: 13%;
}

.datasetform .radiobtns .twelvemonth {
  margin-left: 13%;
}

.datasetform .FromDate {
  /*padding-left: 21%;
    padding-top: 2%;*/
}

.datasetform .uploadfiles {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3d4a52;
  float: left;
  /*margin-left: 20%;*/
  margin-top: 3%;
  margin-bottom: 3%;
  text-align: left;
}

.datasetform .toDate {
  /*padding-top: 2%;
    /* float: left; */
}

.datasetform .FromDate .fromtextfield {
  width: 100%;
}

.datasetform .toDate .totextfield {
  float: left;
  width: 100%;
  /* padding-left: 2%; */
  /*margin-left: 3%;*/
}

.datasetform .recordradiobtns {
  /*margin-left: 20%;*/
  /*margin-top: 1%;*/
}

.datasetform .recordradiobtns .record2 {
  margin-left: 16.5%;
}

.datasetform .recordradiobtns .record3 {
  margin-left: 16.5%;
}

.datasetform .recordradiobtns .record4 {
  margin-left: 16.5%;
}

.datasetform .notavaiable {
  margin-left: 13%;
}

.datasetform .private {
  margin-left: 15%
}

.datasetupload {
  background: #ffffff;
  border: 1px dashed #d8af28;
  border-radius: 2px;
  margin-top: 0;
  /*margin-left: 35%;*/
  /*width: 220%;*/
  height: 218px;
  align-items: center;
}

.uploaddatasetname {
  text-align: left;
  width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3d4a52;
  float: left;
}

.fileUpload {
  width: 100% !important;
  float: left !important;
}

.fileUpload.is-disabled {
  border: none !important;
}

.datasetFormTab {
  width: 732px;
  margin-left: -15px;
}

.headSpace {
  /* padding-bottom: 10px; */
  margin-top: 200x;
  padding-top: -20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3d4a52;
  float: left;
  text-align: left;
  margin-bottom: 30px;
}

.uploadList {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.details {
  max-width: 400px !important;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 138.69%; */
  font-family: "Open Sans";
  font-style: normal;
  color: #3d4a52;
  word-break: break-all;
  text-align: left !important;
}

.viewdetails_table table td {
  max-width: 100px;
  word-break: break-all !important;
}
.mui_snackbar_in_live_api_classname > div :nth-child(2){
   max-width: 1300px !important;
    overflow: scroll !important;
     height: 35px !important; 
}
.datasetform {
  overflow-x: hidden;
}

.datasetform .AddDatasetmainheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #3d4a52;
  float: left;
  /*margin-left: 20%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}
.datasetform .name {
  width: 100%;
  margin-top: 5%;
  /*margin-left: 40%;*/
}
.datasetform .description {
  float: left;
  width: 100% !important;
  margin-top: 5%;
  /*margin-left: 3%;*/
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.datasetform .checkbox1 {
  width: 100%;
  margin-left: 0px;
  margin-top: 1%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* headings */

  color: #3d4a52;
}
.datasetform .AddDatasetsecondaryheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3d4a52;
  float: left;
  /*margin-left: 20%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}
.datasetform .checkbox2 {
  width: 100%;
  /*margin-left: -40px;*/
  margin-top: 1%;
  font-family: "Open Sans";
  float: left;
}
.datasetform .checkbox2 .soil {
  /*float: left;*/
  font-family: "Open Sans";
}
.datasetform .checkbox2 .weather {
  /*float: left;*/
  font-family: "Open Sans";
  /*margin-left: -18px;*/
}
.datasetform .crop {
  width: 100% !important;
  margin-top: 10%;
  /*margin-left: 3%;*/
  float: left;
}
.datasetform .AddDatasetageheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3d4a52;
  float: left;
  /*margin-left: 41%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}
.datasetform .constantswitch {
  margin-top: 6%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3d4a52;
  margin-right: -10px;
  float: right;
}
.datasetform .radiobtns {
  /*margin-left: 20%;*/
}
.datasetform .radiobtns .sixmonth {
  margin-left: 13%;
}
.datasetform .radiobtns .ninemonth {
  margin-left: 13%;
}
.datasetform .radiobtns .twelvemonth {
  margin-left: 13%;
}
.datasetform .FromDate {
  /*padding-left: 21%;
  padding-top: 2%;*/
}

.datasetform .toDate {
  /*padding-top: 2%;
  /* float: left; */
}

.datasetform .FromDate .fromtextfield {
  width: 100%;
}
.datasetform .toDate .totextfield {
  float: left;
  width: 100%;
  /* padding-left: 2%; */
  /*margin-left: 3%;*/
}
.datasetform .recordradiobtns {
  /*margin-left: 20%;*/
  /*margin-top: 1%;*/
}

.datasetform .recordradiobtns .record2 {
  margin-left: 16.5%;
}
.datasetform .recordradiobtns .record3 {
  margin-left: 16.5%;
}
.datasetform .recordradiobtns .record4 {
  margin-left: 16.5%;
}
.datasetform .notavaiable {
  margin-left: 13%;
}
.datasetform .private {
  margin-left: 15%
}
.datasetupload {
  background: #ffffff;
  border: 1px dashed #d8af28;
  border-radius: 2px;
  margin-top: 0;
  /*margin-left: 35%;*/
  /*width: 220%;*/
  height: 218px;
  align-items: center;
}
.uploaddatasetname {
  text-align: center;
  width: 100%;
}

.fileUpload {
  width: 100% !important;
  float: left !important;
}
.dataset-description-in-dataset-details{
  all: unset;
}

.dataset-description-in-dataset-details p{
  display: inline-block;
  overflow: hidden;
  max-height: 60px;
  text-overflow: ellipsis;
  width: 300px;
} 
.cardheaderTitlespecifier span{
  display: inline-block;
  overflow: hidden;
  max-height: 60px;
  text-overflow: ellipsis;
  width: 250px;
}

.add_light_text {
  font-family: "Arial";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #212b36;
}

.tab_header {
  font-family: "Arial";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #637381;
  text-transform: none;
}

.tab_header_selected {
  font-family: "Arial";
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #00a94f;
  text-transform: none;
}

.ml-16 {
  margin-left: 16px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-7 {
  margin-right: 7.25px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.ml-173 {
  margin-left: 173px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}
.datapoint-category-classname > div:nth-child(1) {
  cursor: default !important;
}

.browse_style {
    width: 468px;
}
.imports_style{
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-left: 15px;
}

.list_files {
    width: 370px;
}

.list_upload_style {
    margin-left: 30px;
    max-height: 500px;
    overflow: auto; 
}
/* @import url("https://fonts.googleapis.com/css2?family=Arial:wght@300;400;600;700&display=swap"); */

.add_light_text {
  font-family: "Arial";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #212b36;
}

.tab_header {
  font-family: "Arial";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #637381;
  text-transform: none;
}

.tab_header_selected {
  font-family: "Arial";
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #00a94f;
  text-transform: none;
}

.ml-16 {
  margin-left: 16px;
}

.mt-50 {
  margin-top: 50px;
}

/* @import url("https://fonts.googleapis.com/css2?family=Arial:wght@300;400;600;700&display=swap"); */

.title {
  font-family: "Arial", sans-serif;
  font-weight: 300;
  font-size: 64px;
  line-height: 78px;
  text-align: center;
  color: #585d60;
}

.title_sm {
  font-family: "Arial", sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 78px;
  text-align: center;
  color: #585d60;
  margin-top: 20px;
}

.title_md {
  font-family: "Arial", sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 78px;
  text-align: center;
  color: #585d60;
  margin-top: 50px;
}

.description {
  font-family: "Arial", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
  width: 800px;
}

.description_sm {
  font-family: "Arial", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  width: 800px;
}

.input_field {
  margin-top: 40px !important;
  width: 564px;
  height: 44px;
  border: 1px solid #919eab;
}

.input_field_md {
  margin-top: 40px !important;
  width: 464px;
  height: 44px;
  border: 1px solid #919eab;
}

.input_field_sm {
  margin-top: 40px !important;
  width: 315px;
  height: 31px;
  border: 1px solid #919eab;
}

.input_field .MuiOutlinedInput-root {
  border-radius: 30px;
}

.filter {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 6px;
}

.filter_md {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 6px;
}

.filter_sm {
  margin-top: 50px;
  margin-bottom: 6px;
}

.filter_slg {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 6px;
}

.filter_text {
  margin-left: 12.34px;
  cursor: pointer;
}

.filter_text_md {
  display: flex;
  margin-left: 5.34px;
  cursor: pointer;
}

.ft-12 {
  font-size: 12px;
}

.filter_text_container {
  padding: 5px;
}

.filter_text_container:hover {
  background: #e4e6e8;
  border-radius: 16px;
  cursor: pointer;
}

.filter_text_container_active {
  background: #e4e6e8;
  border-radius: 16px;
  cursor: pointer;
}

.bold_title {
  font-family: "Arial";
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  margin-top: 50px;
  text-align: left;
  margin-bottom: 20px;
}

.bold_title_main {
  font-family: "Arial";
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  margin-top: 10px;
  text-align: left;
  margin-bottom: 20px;
}

.add_new_dataset {
  font-family: "Arial";
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  text-align: center;
  margin: 21px 0px 21px 0px;
}

.add_new_dataset_text {
  margin-top: 0px;
  font-family: "Arial";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  padding: 10px 20px;
}

.card:hover {
  box-shadow: -40px 40px 80px rgba(145, 158, 171, 0.16);
  cursor: pointer;
}

.published {
  text-align: left;
  margin: 21px 0px 0px 20px !important;
}

.published_text {
  /* font-family: 'Avenir'; */
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9ba0a7;
  margin-left: 7px;
}

.d_content_title {
  font-family: "Arial" !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 30px !important;
  color: #00a94f !important;
  text-align: left !important;
  margin: 21px 0px 0px 20px !important;
  white-space: nowrap;
  width: 279px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.organisation {
  text-align: left;
  margin: 5px 0px 0px 20px !important;
  display: flex;
  align-items: center;
}

.organisation_text {
  font-family: "Arial";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-left: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  text-align: left;
}

.d_content_text {
  display: flex;
  margin: 20px 20px 20px 20px !important;
}

.category {
  display: flex;
  align-items: center;
  max-width: 150px;
}

.location {
  margin-left: 15px;
  display: flex;
  align-items: center;
  max-width: 150px;
}

.calendar {
  margin-left: 23px;
  display: flex;
  align-items: center;
}

.category_text {
  margin-left: 7px;
  /* font-family: 'Avenir'; */
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location_text {
  margin-left: 7px;
  /* font-family: 'Avenir'; */
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar_text {
  margin-left: 7px;
  /* font-family: 'Avenir'; */
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.datasets_card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin: 0 auto;
}

.d_button_style {
  font-family: "Arial" !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 26px !important;
  margin-top: 50px !important;
  width: 368px !important;
  height: 48px !important;
  border-radius: 8px !important;
  /* background: #00A94F !important; */
  border: 1px solid rgba(0, 171, 85, 0.48) !important;
  color: #00a94f !important;
}

.d_button_style_md {
  font-family: "Arial" !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  margin-top: 50px !important;
  width: 162px !important;
  height: 36px !important;
  border-radius: 8px !important;
  /* background: #00A94F !important; */
  border: 1px solid rgba(0, 171, 85, 0.48) !important;
  color: #00a94f !important;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .datasets_card {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .category_text {
    font-size: 14px;
  }

  .location_text {
    font-size: 14px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .datasets_card {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 22px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .datasets_card {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .datasets_card {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 22px;
  }
}

@media only screen and (min-width: 1650px) {
  .datasets_card {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 18px;
  }
}
.datasets_list_view_title {
  font-family: "Arial" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.datasets_list_view_text {
  font-family: "Arial" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #000000 !important;
}

.datasets_list_view_name {
  white-space: nowrap;
  width: 183px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.datasets_list_view_details_ellipsis {
  white-space: nowrap;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mr-44 {
  margin-right: 44px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.green_text {
  color: #00a94f !important;
}
.mt114 {
  margin-top: 114px;
}

.mt10 {
  margin-top: 10px !important;
}

.mb139 {
  margin-bottom: 139px;
}

.view_agriculture_heading {
  font-family: "Arial" !important;
  font-weight: 700 !important;
  font-size: 26px !important;
  line-height: 36.06px !important;
  color: #3d4a52 !important;
}

.view_datasets_light_text {
  font-family: "Arial" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.view_datasets_bold_text {
  font-family: "Arial" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.wordWrap {
  word-wrap: break-word;
}

.view_datasets_bold_text_sm {
  font-family: "Arial" !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 24px !important;
  color: #000000 !important;
  max-width: 200px;
  word-wrap: break-word;
}

.ml-134 {
  margin-left: 134px !important;
}

.mt-38 {
  margin-top: 38px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.overflow_x_scroll {
  overflow-x: auto;
}

.w-313 {
  width: 313px;
}

.ml-79 {
  margin-left: 79px;
}

.ml-28 {
  margin-left: 28px;
}

.ml-39 {
  margin-left: 39px;
}

.organisation_icon_card {
  width: 172px !important;
  height: 172px !important;
  border: 1px solid #f2f3f5 !important;
  box-shadow: -40px 40px 80px rgba(145, 158, 171, 0.16) !important;
  border-radius: 16px !important;
}

.type_dataset {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #585454;
  font-family: "Public Sans";
  background: rgba(0, 184, 217, 0.16);
  width: 124px;
  height: 48px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;
  border-radius: 6px;
  margin-left: 30px;
  text-transform: uppercase;
}
.break_word {
  word-break: break-all;
}
.ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.maxWidth400 {
  max-width: 400px;
}

.file_table_column {
    font-family: 'Open Sans' !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22.19px !important;
    color: #3D4A52 !important;
}

.file_table_row {
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 19.42px !important;
    color: #3D4A52 !important;
}

.file_table_column_bg {
    background: #F8F8F8 !important;
}

.file_table_row_bg {
    background: #ffffff !important;
}

.b-1 {
    border: 1px solid #E4E4E4;
}

.b_right {
    border-right: 1px solid rgba(238, 238, 238, 0.5);
}

.b_left {
    border-left: 1px solid rgba(238, 238, 238, 0.5);
}

.p-10 {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.w-100vw {
    width: 100vw;
}
.footer {
    text-align: center;
    background-color: #C09507;
    /*position: fixed;*/
    bottom: 0px;
    min-width: 1440px;
    min-height: 80px;
    vertical-align: middle;
    padding-top: 30px;
    margin: 0px;
}

.footerlink {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    /*margin-left: 100px;*/
}

.footerlink:hover {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.footerlink .footerlinkdisabled {
    pointer-events: none;
}

.footer .row {
    margin: 0px !important;
}
.container{
    margin-top: 50px;
    margin-left: 180px;
    margin-right: 180px;
}

.banner{
    height: 320px;
    width: 100%;
    min-width: 1440px;
    height: 320px;
}

.logo{
    width: 200px;
    height: 200px;

    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(216, 175, 40, 0.13);
    border-radius: 120px;

    min-width: "140px";
    min-height: "140px"; 
    max-width : "140px";
    max-height: "140px";
    margin-top: "-70px";
    text-align: center;
}
:root {
    --theme-color: #c09507;
  }
.guestdiv .supportmaindiv {
  margin-top: 0px;
  margin-left: 110px;
  margin-right: 110px;
}
.guestdiv .supportfilterRow {
  margin-top: 32px;
  width: 1080px;
}
.guestdiv .supportfilterCOlumn {
  height: "fit-content";
  max-width: 346px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  flex-basis: 346px;
}
.guestdiv .supportfilterfirstrow {
  height: 50px;
  background: #f4f4f4;
}
.guestdiv .supportfiltertext {
  padding-top: 16px;
  padding-left: 13px;
}
/* .supportfilterclearall{padding-top:16px;padding-left:13px} */
.guestdiv .supportfilterheadingtext {
  padding-top: 18px;
  padding-left: 15px;
}
.guestdiv .supportfiltersecondrow {
  height: 54px;
  background: var(--theme-color);
}
.guestdiv .supportfiltersecondrowbold {
  height: 54px;
  background: #ffffff;
  border: 1px solid #f7f7f7;
}
.guestdiv .supportallicon {
  padding-left: 13px;
  padding-top: 16px;
}
.guestdiv .supportalltexticon {
  padding-left: 13px;
  padding-top: 19px;
}
.guestdiv .supportfiltercommorrow {
  height: 54px;
  background: var(--theme-color);
}
.guestdiv .supportfiltercommontexticon {
  padding-left: 31px;
  padding-top: 16.5px;
}
.guestdiv .supportfiltercommontext {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  padding-left: 11px;
  padding-top: 20px;
}
.guestdiv .supportfiltercommorrowbold {
  height: 54px;
  background: #ffffff;
  border: 1px solid #f7f7f7;
}
.guestdiv .supportfiltercommontexticonbold {
  padding-left: 31px;
  padding-top: 16.5px;
}
.guestdiv .supportfiltercommontextbold {
  font-weight: 400;
  font-size: 14px;
  color: #3d4a52;
  padding-left: 11px;
  padding-top: 20px;
}
.guestdiv .supportSecondCOlumn {
  width: 732px;
  margin-left: 20px;
}
.guestdiv .supportcardfirstcolumn {
  margin-top: 20px;
  text-align: left;
}
.guestdiv .supportcardsecondcolumn {
  text-align: right;
  margin-right: 10px;
  margin-top: 20px;
}
.guestdiv .supportcardsecondcolumndata {
  text-align: right;
  margin-right: 20px;
  margin-top: 20px;
}
.guestdiv .supportcardmargintop {
  margin-top: -19px;
}
.guestdiv .supportcardmargintop20px {
  margin-top: 20px;
}
.guestdiv .supportcardmargintop0px {
  margin-top: 0px;
}
.guestdiv .supportcard .MuiCardHeader-title {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #3d4a52;
}
.guestdiv .supportfilterthirdrow {
  height: 171px;
  background: #ffffff;
}
.guestdiv .supportfilterthirdrowhighlight {
  height: 171px;
  background: #fff7dc;
}
.guestdiv .supportfilterthirdrowheadingtext {
  padding-top: 18px;
  padding-left: 13px;
}
.guestdiv .supportcardfromdate {
  margin-top: 54px;
  margin-left: -51px;
  width: 150px;
}
.guestdiv .supportcardtodate {
  margin-top: 54px;
  margin-left: 20px;
  width: 150px;
}
.guestdiv .supportsubmitbrn .btncolor {
  color: var(--theme-color);
  border-color: var(--theme-color);
  border-radius: 0;
  text-transform: capitalize;
  background: #ffffff;
  border: 1px solid #d8af28;
  border-radius: 2px;
}
.guestdiv .supportsubmitbrn {
  margin-left: 253px;
}
.guestdiv .marginleft165px {
  margin-left: 165px;
}
.guestdiv .disbaledatesubmitbtn {
  text-transform: capitalize !important;
  border-radius: 2px;
  background: #ffffff !important;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  opacity: 0.5;
}
.guestdiv button.enabledatesubmitbtn {
  text-transform: capitalize !important;
  color: #ffffff;
  background: #c09507 !important;
  border-radius: 2px;
  border: 2px solid #c09507 !important;
}
.guestdiv button.enabledatesubmitbtn:hover {
  background-color: #c09507 !important;
}
.guestdiv .supportcardsecondclosedcolumndata {
  text-align: right;
  margin-right: 9px;
  margin-top: 20px;
}
.guestdiv .supportcardfromdate .MuiTextField-root {
  background: #f1f1f1;
}
.guestdiv .supportcardtodate .MuiTextField-root {
  background: #f1f1f1;
}
.guestdiv .supportfirstmaindiv {
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 50px;
  background: #fcfcfc;
}
.guestdiv .supportViewDetailsback {
  margin-left: 14px;
  font-weight: 400;
  font-size: 14px;
  color: #3491ee;
}
.guestdiv .supportViewDetailsbackimage {
  margin-left: 94px;
  margin-top: 54px;
  text-align: left;
  cursor: pointer;
}
.guestdiv .supportViewDeatilsSecondRow {
  border: 1px solid rgba(238, 238, 238, 0.5);
  margin-top: 23px;
}
.guestdiv .oversizemb-uploadimglogo {
  width: 100%;
  color: red;
}
.guestdiv .marginrowtop8px {
  margin-top: 8px;
}
.guestdiv .resolution textarea {
  border: none;
  border-bottom: 1px solid black;
  background: #f1f1f1;
  border-radius: 2px;
}
.guestdiv .messagedescription {
  height: 117px;
  background: #ffffff;
  border: 1px solid #f9eabc;
  border-radius: 2px;
  overflow: scroll;
  width: 306px;
  padding: 10px;
}
.guestdiv .nodataavailable {
  margin-left: 50%;
  margin-top: 10%;
}
.guestdiv .requestChange {
  font-weight: 400;
  font-size: 14px;
  color: #c4c4c4;
}

.loding-img-containe{
    width: 100%;
    height: 80%;
}

.loding-img-div{
    margin: 15% auto;
}
.loding-img-div > img{
    height: 150px;
}
.loding-img-div > h1{
    color: #050504cc;
    font-size: 22px;
    margin-top: 40px;
    font-weight: 400;
}
.loding-img-div > h3{
    color: #050504cc;
    font-size: 18px;
    /* margin-top: 40px; */
    font-weight: 400;
}
/* CSS */
.button-87 {
    /* margin: 10px; */
    padding: 10px 30px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: #534104;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #d69437e9;
    background-image: linear-gradient(45deg, #FFDB64 0%, #d69437e9 51%, #FFDB64 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    text-transform: capitalize;
    width: 200px;
    height: 50px;
    font-size: 16px;
}

.button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.button-87:active {
    transform: scale(0.95);
}
.intro-image {
  position: absolute;
  width: 90px;
  height: 90px;
  left: 70px;
  top: 278px;
  /*top: 255px;*/
  /* width: 50px;
  height: 50px; */
  border-radius: 50%;
  margin-top: 1%;

  background: #f8f8f8;
}
.leftheader {
  position: absolute;
  width: 308px;
  height: 27px;
  left: 182px;
  top: 287px;
  /*top: 265px;*/
  text-align: start;
  /*padding-left: 18px;*/

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27.24px;

  color: #c09507;
}
.leftcontent {
  position: absolute;
  width: 528px;
  height: 44px;
  left: 182px;
  top: 320px;
  /*top: 300px;*/
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21.79px;
  text-align: left;
  color: #3d4a52;
}
.intro-image2 {
  position: absolute;
  width: 90px;
  height: 90px;
  left: 70px;
  /* top: 432px; */
  top: 418px;
  border-radius: 50%;
  background: #f8f8f8;
}
.leftheader2 {
  position: absolute;
  width: 418px;
  height: 27px;
  left: 182px;
  top: 427px;
  /*top: 402px;*/
  text-align: start;
  /*padding-left: 18px;*/

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;

  color: #c09507;
}
.leftcontent2 {
  position: absolute;
  width: 528px;
  height: 44px;
  left: 182px;
  top: 460px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3d4a52;
  text-align: left;
}

.leftheader3 {
  position: absolute;
  width: 418px;
  height: 27px;
  left: 182px;
  top: 567px;
  text-align: start;
  /*padding-left: 18px;*/
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;

  color: #c09507;
}
.intro-image3 {
  position: absolute;
  width: 90px;
  height: 90px;
  left: 70px;
  top: 558px;
  border-radius: 50%;
  background: #f8f8f8;
}
.leftcontent3 {
  position: absolute;
  width: 528px;
  height: 44px;
  left: 182px;
  top: 600px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3d4a52;
  text-align: left;
}

.intro-image4 {
  position: absolute;
  width: 90px;
  height: 90px;
  left: 70px;
  top: 683px;
  border-radius: 50%;
  background: #f8f8f8;
}
.leftheader4 {
  position: absolute;
  width: 418px;
  height: 27px;
  left: 182px;
  top: 692px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: start;
  color: #c09507;
}
.leftcontent4 {
  position: absolute;
  width: 528px;
  height: 44px;
  left: 182px;
  top: 725px;
  /*top: 720px;*/
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 73px;
  color: #3d4a52;
  text-align: left;
}

.intro-image {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 70px;
    top: 278px;
    /*top: 255px;*/
    /* width: 50px;
    height: 50px; */
    border-radius: 50%;
    margin-top: 1%;
  
    background: #f8f8f8;
  }
  .leftheader {
    position: absolute;
    width: 308px;
    height: 27px;
    left: 182px;
    top: 287px;
    /*top: 265px;*/
    text-align: start;
    /*padding-left: 18px;*/
  
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27.24px;
  
    color: #c09507;
  }
  .leftcontent {
    position: absolute;
    width: 528px;
    height: 44px;
    left: 182px;
    top: 320px;
    /*top: 300px;*/
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.79px;
    text-align: left;
    color: #3d4a52;
  }
  .intro-image2 {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 70px;
    /* top: 432px; */
    top: 418px;
    border-radius: 50%;
    background: #f8f8f8;
  }
  .leftheader2 {
    position: absolute;
    width: 418px;
    height: 27px;
    left: 182px;
    top: 427px;
    /*top: 402px;*/
    text-align: start;
    /*padding-left: 18px;*/
  
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
  
    color: #c09507;
  }
  .leftcontent2 {
    position: absolute;
    width: 528px;
    height: 44px;
    left: 182px;
    top: 460px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3d4a52;
    text-align: left;
  }
  
  .leftheader3 {
    position: absolute;
    width: 418px;
    height: 27px;
    left: 182px;
    top: 567px;
    text-align: start;
    /*padding-left: 18px;*/
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
  
    color: #c09507;
  }
  .intro-image3 {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 70px;
    top: 558px;
    border-radius: 50%;
    background: #f8f8f8;
  }
  .leftcontent3 {
    position: absolute;
    width: 528px;
    height: 44px;
    left: 182px;
    top: 600px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3d4a52;
    text-align: left;
  }
  
  .leftintroparticipant .intro-image4 {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 70px;
    top: 683px;
    border-radius: 50%;
    background: #f8f8f8;
  }
  .leftintroparticipant .leftheader4 {
    position: absolute;
    width: 418px;
    height: 27px;
    left: 182px;
    top: 692px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: start;
    color: #c09507;
  }
  .leftintroparticipant .leftcontent4 {
    position: absolute;
    width: 528px;
    height: 44px;
    left: 182px;
    top: 725px;
    /*top: 720px;*/
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 73px;
    color: #3d4a52;
    text-align: left;
  }
  
.intro-image {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 70px;
    top: 278px;
    /*top: 255px;*/
    /* width: 50px;
    height: 50px; */
    border-radius: 50%;
    margin-top: 1%;
  
    background: #f8f8f8;
  }
  .leftheader {
    position: absolute;
    width: 308px;
    height: 27px;
    left: 182px;
    top: 287px;
    /*top: 265px;*/
    text-align: start;
    /*padding-left: 18px;*/
  
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27.24px;
  
    color: #c09507;
  }
  .leftcontent {
    position: absolute;
    width: 528px;
    height: 44px;
    left: 182px;
    top: 320px;
    /*top: 300px;*/
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.79px;
    text-align: left;
    color: #3d4a52;
  }
  .intro-image2 {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 70px;
    /* top: 432px; */
    top: 418px;
    border-radius: 50%;
    background: #f8f8f8;
  }
  .leftheader2 {
    position: absolute;
    width: 418px;
    height: 27px;
    left: 182px;
    top: 427px;
    /*top: 402px;*/
    text-align: start;
    /*padding-left: 18px;*/
  
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
  
    color: #c09507;
  }
  .leftcontent2 {
    position: absolute;
    width: 528px;
    height: 44px;
    left: 182px;
    top: 460px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3d4a52;
    text-align: left;
  }
  
  .leftheader3 {
    position: absolute;
    width: 418px;
    height: 27px;
    left: 182px;
    top: 567px;
    text-align: start;
    /*padding-left: 18px;*/
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
  
    color: #c09507;
  }
  .intro-image3 {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 70px;
    top: 558px;
    border-radius: 50%;
    background: #f8f8f8;
  }
  .leftcontent3 {
    position: absolute;
    width: 528px;
    height: 44px;
    left: 182px;
    top: 600px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3d4a52;
    text-align: left;
  }
  
  .leftintroparticipant .intro-image4 {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 70px;
    top: 683px;
    border-radius: 50%;
    background: #f8f8f8;
  }
  .leftintroparticipant .leftheader4 {
    position: absolute;
    width: 418px;
    height: 27px;
    left: 182px;
    top: 692px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: start;
    color: #c09507;
  }
  .leftintroparticipant .leftcontent4 {
    position: absolute;
    width: 528px;
    height: 44px;
    left: 182px;
    top: 725px;
    /*top: 720px;*/
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 73px;
    color: #3d4a52;
    text-align: left;
  }
  
.signoutbtn-navbar {
  background: #ffffff !important;
  padding: 5px 15px !important;
  color: #d8af28 !important;
  outline: none !important;
  border: 1px solid #d8af28 !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
  /* text-decoration: none !important; */
  /* Second Nav */
  text-transform: none !important;
  /* margin-left: 24px !important; */
  font-family: "Open Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  border-radius: 2px;
}

.navtext{
  vertical-align: middle;
  margin-top: 24px;
  margin-left: 0px;
  color: black;
}

.navlink{
  color: #808080;
display: flex;
align-items: center;
text-decoration: none;
margin-left: 29px;
/*margin-bottom: -5px;*/
/*margin-top: 25px;*/
margin-right: 29px;
padding: 0px;
height: 100%;
cursor: pointer;

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 138.69%;
/* or 19px */

/* headings */
color: #A3B0B8;
}
.navlink.active {
	border-bottom: 8px solid #c09507;
	margin-bottom: 5px;
	margin-top: 10px;
	color: #3D4A52;

}
.navlink.active > .boldimage {
	display: block;
}
.navlink.active > .nonboldimage {
	display: none;
}
.navlink:hover {
    text-decoration: none;
    color: #A3B0B8;
}
.navlink.active:hover {
	color: #3D4A52;
}
.navbar_col{
  display: flex;
  position: relative;
  justify-content:flex-start;
}
.navbar_right_col{
  display: flex;
  width: 700px;
  justify-content:flex-end; 
  flex-direction: row-reverse;
   
}
.title {
  font-family: "Arial", sans-serif;
  font-weight: 300;
  font-size: 64px;
  line-height: 78px;
  text-align: center;
  color: #585d60;
}

.description {
  font-family: "Arial", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
  width: 956px;
}

.input_field {
  margin-top: 40px !important;
  width: 564px;
  height: 44px;
  border: 1px solid #919eab;
}

.input_field .MuiOutlinedInput-root {
  border-radius: 30px;
}

.bold_title {
  font-family: "Arial";
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  margin-top: 100px;
  text-align: left;
  margin-bottom: 20px;
}

.card:hover {
  box-shadow: -40px 40px 80px rgba(145, 158, 171, 0.16);
  cursor: pointer;
}

.img_container {
  margin: 40px 53px 30px 40px;
}

.content_title {
  font-family: "Arial";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #00a94f;
  width: 287px;
  text-align: start;
  margin: 0px 41px 30px 40px !important;
}

.content_text {
  margin-left: 40px;
  margin-bottom: 40px;
}

.content_text1 {
  font-family: "Arial";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  color: #000000;
}

.content_text2 {
  font-family: "Arial";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  color: #000000;
  margin-top: 2px;
}

.participants_card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin: 0 auto;
}

.button_style {
  font-family: "Arial" !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 26px !important;
  margin-top: 50px !important;
  width: 368px !important;
  height: 48px !important;
  border: 1px solid rgba(0, 171, 85, 0.48) !important;
  border-radius: 8px !important;
  color: #00a94f !important;
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .title {
    font-weight: 300;
    font-size: 32px;
    line-height: 60px;
    text-align: center;
    color: #585d60;
  }

  .description {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin-top: 32px;
    width: 700px;
  }

  .input_field {
    margin-top: 40px !important;
    width: 364px;
    height: 44px;
    border: 1px solid #919eab;
  }

  .bold_title {
    font-weight: 400;
    font-size: 22px;
    line-height: 39px;
  }

  .participants_card {
    grid-template-columns: repeat(2, 1fr);
  }

  .card {
    max-width: 100% !important;
  }

  .button_style {
    font-weight: 700 !important;
    font-size: 13px !important;
    line-height: 22px !important;
    width: 360px !important;
    height: 48px !important;
  }

  .footer_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .footer_light_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .footer_dark_text {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .link {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .button_text {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 22px !important;
  }

  .input_field_subscribe {
    width: 364px !important;
    height: 56px;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-weight: 300;
    font-size: 27px;
    line-height: 60px;
    text-align: center;
    color: #585d60;
  }

  .description {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin-top: 32px;
    width: 700px;
  }

  .input_field {
    margin-top: 40px !important;
    width: 364px;
    height: 44px;
    border: 1px solid #919eab;
  }

  .participants_card {
    grid-template-columns: repeat(1, 1fr);
  }

  .card {
    max-width: 100% !important;
  }

  .button_style {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 22px !important;
    width: 260px !important;
    height: 48px !important;
  }

  .footer_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .footer_light_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .footer_dark_text {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .link {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .button_text {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 22px !important;
  }

  .input_field_subscribe {
    width: 364px !important;
    height: 56px;
  }
}


.dataset-card-header :nth-child(2){
    display: flex;
    flex-direction: column;
}

.accountsettingsupload {
  background: #ffffff;
  border: 1px dashed #d8af28;
  border-radius: 2px;
  margin-top: 8%;
  margin-left: 27%;
  width: 240%;
  height: 218px;
}

.accountsettingsheader {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #3d4a52;
  padding-top: 2%;
}

.projectform .connectormainheading {
  color: #3d4a52;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  margin-top: 8% !important;
  text-align: start;
  margin-left: 6%;
}
.projectform .supportViewDetailsbackimage {
  margin-left: 14px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Open Sans";
  color: #3491ee;
  margin-top: 0px;
  margin-left: 7%;
}
.projectform .department {
  padding-left: 8%;
  margin-top: 2%;
  text-align: left;
}
.projectform .project {
  /* margin-top: 2%; */
  text-align: left;
  margin-top: 2.7%;
}
.projectform .projectName {
  width: 80%;
}

.projectform .description {
  float: left;
  /* width: 80% !important; */
  margin-top: 2%;
  /* padding-left: 2% !important; */
  text-align: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 7%;
}
.projectform .descriptionName {
  width: 83.5% !important;
}

.main-container {
  text-align: left;
  width: 1150px !important;
  margin: auto;
  background: #ffffff;
  border-radius: 30px;
}
.datapoint-name-input-box {
  width: 950px;
  height: 50px;
  margin: 20px auto !important;
}
.datapoint-name-input-box-description {
  width: 950px;
  /* height: 120px; */
}
.datapoint-name-input-box-description > div {
  /* height: 100%; */
}
.datapoint-name-input-box-description > div > textarea {
  /* height: 120px !important; */
  min-height: 120px !important;
  max-height: 120px !important;
  /* overflow: scroll !important; */
}
.data-point-input-box-container {
  margin: auto !important;
}
.title-container,
.data-point-input-box-container,
.datapoint-add-button-classname {
  margin: auto;
  width: 950px !important;
}
.datapoint-add-button-classname {
  margin: 50px auto;
}
.title-container > h1 {
  font-weight: 700;
  font-size: 32px;
}
.title-container > p {
  font-weight: 300;
  font-size: 18px;
}
.datapoint-add-button-classname {
  text-align: right;
}
.datapoint-add-button {
  width: 172px !important;
  height: 48px !important;
  text-transform: none !important;
  color: #c09507 !important;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  background: #ffffff !important;
  border: 2px solid #c09507 !important;
  box-shadow: none !important;
}
.datapoint-add-button:hover {
  background: #c09507 !important;
  color: #ffffff !important;
}
.finish-later-button {
  width: 172px !important;
  height: 48px !important;
  text-transform: none !important;
  color: #c09507 !important;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  background: #ffffff !important;
  /* border: 2px solid #c09507 !important; */
  box-shadow: none !important;
  border: none !important;
  color: black;
  background-color: #ffffff !important;
}
.finish-later-button:hover {
  border: 2px solid #c09507 !important;
}

.attribute-container {
  width: 950px;
  margin: auto;
}
.attribute-accordion-titile :nth-child(1) {
  width: 90% !important;
}
.datapoint-name-input-box input {
  height: 15px !important;
}
.attribute-container > h1 {
  font-weight: 700;
  font-size: 32px;
  margin: 20px auto;
}
.accordion-title {
  font-weight: 700 !important;
  font-size: 20px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.standardization-accordion-description {
  word-wrap: break-word;
  text-align: left;
}
.datapoint-attribute-input-box {
  width: 100%;
  /* margin: 0 20px 20px 0 !important; */
}
.datapoint-attribute-input-box1 {
  width: 100%;
}

.accordion-main-classname {
  box-shadow: 0px 20px 40px -4px rgba(145, 158, 171, 0.16) !important;
}
#add-attribute-icon-id {
  color: black !important;
  /* font-size: small; */
}
.add-datapoint-svg {
  cursor: pointer !important;
  text-align: left;
}
.update-category-button {
  width: 90px !important;
  height: 30px !important;
  text-transform: none !important;
  color: #c09507 !important;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  background: #ffffff !important;
  border: 2px solid #c09507 !important;
  box-shadow: none !important;
}

.connectors .supportmaindiv{margin-top:50px;margin-left:110px;margin-right:110px}
.connectors .supportfilterRow{margin-left: -73px; margin-top:32px;width: 1240px;}
.connectors .supportfilterCOlumn{ height:-webkit-fit-content; height:-moz-fit-content; height:fit-content;max-width:346px;background: #FFFFFF;border: 1px solid #E4E4E4;border-radius: 2px;flex-basis:346px}
.connectors .supportfilterfirstrow{ height:50px;background:#F4F4F4}
.connectors .supportfiltertext{padding-top:16px;padding-left:13px}
.connectors .supportfilterheadingtext{padding-top:18px;padding-left:15px}
.connectors .supportfiltersecondrow{ height:54px;background:var(--theme-color);}
.connectors .supportfiltersecondrowbold{ height:54px;background:#FFFFFF;border: 1px solid #F7F7F7;}
.connectors .supportallicon{padding-left: 13px;padding-top: 16px;}
.connectors .supportalltexticon{padding-left: 13px;padding-top: 19px;}
.connectors .supportfiltercommorrow{height:54px;background:var(--theme-color)}
.connectors .supportfiltercommontexticon{padding-left: 31px;padding-top: 16.5px;}
.connectors .supportfiltercommontext{font-weight: 600;font-size: 14px;color: #FFFFFF;padding-left: 11px;padding-top:20px;}
.connectors .supportfiltercommorrowbold{height:54px;background:#FFFFFF;border: 1px solid #F7F7F7;}
.connectors .supportfiltercommontexticonbold{padding-left: 31px;padding-top: 16.5px;}
.connectors .supportfiltercommontextbold{font-weight: 400;font-size: 14px;color: #3D4A52;padding-left: 11px;padding-top:20px;}
.connectors .supportSecondCOlumn{ width:732px}
.connectors .supportcardfirstcolumn{margin-top: 20px;text-align: left;}
.connectors .supportcardfirstcolumngeo{margin-top: 20px;text-align: left; overflow: hidden; text-overflow: ellipsis; 
  display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;}
.connectors .supportcardsecondcolumn{text-align: right;margin-right: 10px;margin-top: 20px;}
.connectors .supportcardsecondcolumndata{text-align: right;margin-right: 20px;margin-top: 20px; overflow: hidden; text-overflow: ellipsis; 
  display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;}
.connectors .supportcardmargintop{margin-top: -19px;}
.connectors .supportcardmargintop20px{margin-top:20px}
.connectors .supportcardmargintop0px{margin-top:0px}
.connectors .supportcard .MuiCardHeader-title{font-weight: 600 !important;font-size: 14px !important; color: #3D4A52;}
.connectors .supportfilterthirdrow{ height:171px;background:#FFFFFF}
.connectors .supportfilterthirdrowhighlight{ height:171px;background:#FFF7DC}
.connectors .supportfilterthirdrowheadingtext{padding-top:18px;padding-left:13px}
.connectors .supportcardfromdate{margin-top: 54px;margin-left: -51px;width: 150px;}
.connectors .supportcardtodate{margin-top: 54px;margin-left:20px;width: 150px;}
.connectors .supportsubmitbrn .btncolor{color: var(--theme-color);border-color:var(--theme-color);border-radius:0;text-transform:capitalize;background: #FFFFFF; border: 1px solid #D8AF28;border-radius: 2px} 
.connectors .supportsubmitbrn{margin-left: 218px;}
.connectors .marginleft165px{margin-left: 165px;}
.connectors .disbaledatesubmitbtn{right:35px;text-transform: capitalize !important;border-radius: 2px; background: #FFFFFF !important;border:1px solid #C09507 !important;color:#585D60 !important;opacity: 0.5;  width: 116px; height: 34px; }
.connectors button.enabledatesubmitbtn{right:35px;text-transform: capitalize !important;color: #FFFFFF;height:42px; background:#C09507 !important;border-radius: 2px;border:2px solid #C09507 !important; width: 116px; height: 34px; }
.connectors button.enabledatesubmitbtn:hover{right:35px;background-color: #C09507 !important;}
.connectors .supportcardsecondclosedcolumndata {text-align: right; margin-right: 9px; margin-top: 20px;}
.connectors .supportcardfromdate .MuiTextField-root{background: #F1F1F1;}
.connectors .supportcardtodate .MuiTextField-root{background: #F1F1F1;}
.connectors .supportfirstmaindiv{margin-left: 70px;margin-right: 70px;background: #FCFCFC;}
.connectors .supportViewDetailsback{margin-left: 14px;font-weight: 400;font-size: 14px; color: #3491EE;}
.connectors .supportViewDetailsbackimage{margin-left: 94px;margin-top: 54px;text-align: left;cursor: pointer;}
.connectors .supportViewDeatilsSecondRow{border: 1px solid rgba(238, 238, 238, 0.5);margin-top:23px;}
.connectors .cardConnectorName{font-size:14px;font-weight:600;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 50px;margin-top: 20px;text-align: left;}
.connectors .oversizemb-uploadimglogo {
    width: 100%;
    color: red;
  }
.connectors .marginrowtop8px{margin-top:8px}
/* .resolution textarea{border: none;
    border-bottom: 1px solid black;
    background: #F1F1F1;
    border-radius: 2px;
} */
.connectors .messagedescription{
height: 117px;
background: #FFFFFF;
border: 1px solid #F9EABC;
border-radius: 2px;
overflow: scroll;
width: 306px;
padding: 10px;
}
.connectors .nodataavailable{
  margin-left: 50%;
  margin-top: 10%;
}

.datasetfiltertext{
width: 35px;
height: 19px;
left: 193px;
top: 136px;

margin-left: 5px;
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
/* identical to box height */

align-items: center;

color: #3A3A3A;
}
.filterClearAll{

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */
display: flex;
align-items: center;
-webkit-text-decoration-line: underline;
        text-decoration-line: underline;

color: #3491EE;
}
.container{
    margin-top: 50px;
    margin-left: 180px;
    margin-left: 180px;
}

.banner{
    height: 320px;
    width: 100%;
    min-width: 1440px;
    height: 320px;
}

.logo{
    margin-top: -100px;
    padding: 30px;
    grid-gap: 10px;
    gap: 10px;

    width: 200px;
    height: 200px;

    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(216, 175, 40, 0.13);
    border-radius: 120px;
}
